import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import MissionTitle from "./partials/MissionTitle";
const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const missionTitle = {
    title: "About & Contact",
  };

  const sectionHeader = {
    title: "Teaching customized for the student",
    paragraph:
      "Our team of tutors work to acheive mastery in key skills for our students. Content used in teaching is created directly from state standards by our content creation team.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <hr />
            <br />
            <MissionTitle data={missionTitle} className="center-content" />
            <div id="about" className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Who are we
                </div>
                <h3 className="mt-0 mb-12">Students Teaching Students.</h3>
                <p className="m-0">
                  The only way to grow is if we teach each other. We connect and
                  teach students so learning can be an experience. Working with
                  different organizations, we strive to provide quality
                  education to everyone.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-01.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Our Current Project - Project30
                </div>
                <h3 className="mt-0 mb-12">30 DAYS. 30 TUTORS. 30 LEARNERS.</h3>
                <p className="m-0">
                  We believe that sharing knowledge is essential for success.
                  Project30 will match 30 students to 30 tutors in a 30 day long
                  program where tutors will aid students in grade-level
                  academics.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-02.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item" id="contact">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Contact
                </div>
                <h3 className="mt-0 mb-12">We'd love to hear from you!</h3>
                <p className="m-0">
                  Email us at communications@reviewtutor.org for any questions
                  you have. We'll respond as soon as possible, we promise!
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/features-split-image-03.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <hr />
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Our Partners
                </div>
                <h3 className="mt-0 mb-12">Educational For All</h3>
                <p className="m-0">
                  Education for All focuses on rural outreach programs which
                  provide quality education free of charge to children from all
                  walks of life irrespective of cast, creed, religion or any
                  other background. In many countries including India, quality
                  education is no longer affordable to the general public while
                  private institutions charge hefty fees and often also require
                  large donations. "Our goal is to provide free quality
                  education to qualified children along with free food and
                  shelter to those children who cannot afford it." Check out their website here: <a href="https://www.educationforall.co/">https://www.educationforall.co/</a>
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
              <a href="https://www.educationforall.co/">
                <Image
                  src={require("./../../assets/images/efa.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
