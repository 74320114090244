import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
const FooterNav = ({ className, ...props }) => {
  const sendMail = () => {
    const mailto = "mailto:reviewtutors@gmail.com?subject=Support";
    window.location.href = mailto;
  };

  const toDonate = () => {
    const donate = "https://www.gofundme.com/f/ze5x9-review-tutor-project30?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cf+share-flow-1"
    window.location.href = donate;
  }

  const pointer = {
    cursor: 'pointer',
  }
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link style={pointer} to="about" spy={true} smooth={true} offset={-240}>
            About
          </Link>
        </li>
        <li>
          <Link style={pointer} to="contact" spy={true} smooth={true} offset={-200}>
            Contact
          </Link>
        </li>

        <li>
          <Link style={pointer} onClick={toDonate}>
            Donate
          </Link>
        </li>
        <li>
          <Link style={pointer} onClick={sendMail}>Support</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
