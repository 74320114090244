import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Looking to join the team?',
    paragraph: "At Review Tutor, we are constantly innovating and looking to make an impact. We have different teams so we utilize everyone's skills to make an impact together. This is what members of our team have to say..."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  — I have been a long-time member of Review Tutor and my time here has been great. 
                  From coding the website to working on outreach for our projects, I have enjoyed everything, and I have learned a lot along the way.

                  I am very excited for the future of this organization!
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Shaleen B.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    Content Manager
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  — As Project Manager, I've worked with my team to start different programs within the organization. 
                      As a part of this initiative, I've developed on countless skills such as leadership and management.
                      I am excited to see where this organization will go with the immense impact on students of our generation.
                      </p>
                      
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Pranav E.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    Project Manager
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  — From working with the team, I've learned so much. 
                  Everyone has been kind, motivational, and welcoming since day one. By being a part of this organization, I've developed key skills in areas such as communication and management.
                  I'm looking forward to contribute to the lasting impact we can make on our students!
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Vidhi A.</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    Operations Director
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;